<template>
  <div >
     <div class="CopBox" >
          <img  class="getCoupon"
                  alt="GetCoupon"
                  src="@/assets/GetCoupon.png">
        </div>
        <div class="LabelBox">
          <p>
            <span class="GetCouponTit">全部</span>
            <span class="GetCouponMsg" @click="jumpCoupon">我的优惠券 <i class="el-icon-arrow-right" aria-hidden="true"></i></span>
          </p>
        </div>
        <div class="CouponBox" >
          <el-col class="getCouponBox" :gutter="24">
              <GetCouponItem v-for='(item,index) in couponlist' :key="index" :span="8" :couponItem="item"></GetCouponItem>
          </el-col>
      </div>
  </div>
</template>

<style lang="less" scoped>
.CopBox{
  width: 1200px;
   margin:0px auto;
   height: 310px;
   .getCoupon{
      width: 100%;
      height: 100%;

   }
}
.CouponBox{
  width: 1200px;
   margin:0px auto;
}
.LabelBox{
  width: 100%;
  height:60px;
  background: #fff;
  margin-bottom: 20px;
  p{
     width: 1200px;
     margin:0px auto;
     line-height: 60px;
     .GetCouponTit{
       float: left;
        padding:0  20px;
        text-align: center;
        font-size: 16px;
        color: #333;
        font-weight: bold;
        &:hover{
          cursor: pointer;
          border-bottom:2px solid #FFD303 ;
        }
      }
      .GetCouponMsg{
        color: #666;
        font-size: 14px;
        text-align: center;
        float:right;
        cursor: pointer;
      }
  }
}

 .coupon:nth-child(3n + 0) {
      margin-right: 0px;
    }
</style>

<script>
import {GetCouponList} from 'api/member.js';
const GetCouponItem = ()=>import("components/common/GetCouponItem.vue");
export default {
  name:"GetCoupon",
   data() {
      return {
           couponlist:'',
           total:0
      }
   },
   methods:{
      //  获取优惠券列表
    getCouponList(){
        let params={
          current:this.current,
          size:this.size
        }
        GetCouponList(params).then(data=>{
          this.couponlist=data.data.data
           this.total = Number(data.data.data.length);
        })
    },
    jumpCoupon() {
      this.$router.push({ name: "Coupon" });
    },
   },
   created(){
     this.getCouponList()
    },
  components:{
      GetCouponItem
  } 
};
</script>
